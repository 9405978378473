import { EventKey } from '../enums';
export const WithEvent = (Base) => {
    return class PlayerWithEvent extends Base {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        constructor(...args) {
            super(...args);
            this.attachAdapterCallbacks();
        }
        attachAdapterCallbacks() {
            this.adapter.onPlaybackDurationChanged = (duration) => {
                this._duration = duration;
            };
            this.adapter.onPlayerReady = () => {
                this.triggerEvent(EventKey.PLAYER_READY);
            };
            this.adapter.onSeekableRangeChanged = (seekableRange) => {
                if (this._seekableRange?.start !== seekableRange?.start ||
                    this._seekableRange?.end !== seekableRange?.end) {
                    this.setPlayerState({
                        seekableRange,
                    });
                }
            };
            this.adapter.onPositionChanged = (position) => {
                if (this.seekPosition != null) {
                    return;
                }
                this._currentPosition = position;
                this.triggerEvent(EventKey.CURRENT_POSITION_CHANGED, {
                    position,
                });
            };
            this.adapter.onAvailableAudioTracksChanged = (audioTracks) => {
                this.availableAudioTracks = audioTracks;
                this.triggerEvent(EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED, {
                    tracks: audioTracks,
                });
            };
            this.adapter.onAvailableSubtitlesTracksChanged = (subtitleTracks) => {
                this.availableSubtitlesTracks = subtitleTracks;
                this.triggerEvent(EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED, {
                    tracks: subtitleTracks,
                });
            };
            this.adapter.onSeeked = (position) => {
                this.seekPosition = null;
                this._currentPosition = position;
                this.triggerEvent(EventKey.SEEKED, {
                    position,
                });
            };
            this.adapter.onSelectedSubtitlesTrack = (targetTrack) => {
                this.selectedSubtitlesTrack = targetTrack;
                this.triggerEvent(EventKey.SELECTED_SUBTITLES_TRACK_CHANGED, {
                    targetTrack,
                });
            };
            this.adapter.onSelectedAudioTrackChanged = (targetTrack) => {
                this.selectedAudioTrack = targetTrack;
                this.triggerEvent(EventKey.SELECTED_AUDIO_TRACK_CHANGED, {
                    targetTrack,
                });
            };
            this.adapter.onPlaybackStateChanged = (state) => {
                this._playbackState = state;
                this.triggerEvent(EventKey.PLAYBACK_STATE_CHANGED, {
                    state,
                });
                this.setPlayerState({});
            };
            this.adapter.onError = (error) => {
                this.handleError(error);
            };
        }
        on = (eventKey, listener) => {
            this._eventTarget?.addEventListener(eventKey, listener);
        };
        off = (eventKey, listener) => {
            this._eventTarget?.removeEventListener(eventKey, listener);
        };
    };
};
