import React from 'react';
import { PlayerView } from '@zattoo/playback-sdk-react-native';
import { PlayerManager } from './player-manager';
import { styles } from './styles';
export const Player = () => {
    const playerManager = PlayerManager.getInstance();
    const player = playerManager.getPlayer();
    if (!player) {
        return null;
    }
    return (React.createElement(PlayerView, { player: player, style: styles.player }));
};
