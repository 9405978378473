import { registerTimeshift, getTimeshiftStream, } from '@zattoo/zapi';
import { TimeshiftStatus } from '@zattoo/zapi/lib/stream/enums';
import { MediaType } from '../../enums';
import { SubtitlesCodec, getStreamingProtocol, } from '../../../capability';
import * as timeshiftUtils from './utils';
import { EventKey } from '../../../player/enums';
export const PlayerWithRegisteredTimeshift = (Base) => {
    return class extends Base {
        #registeredTimeshiftAvailability = TimeshiftStatus.UNAVAILABLE;
        #channelId = null;
        #timeshiftInfo = null;
        #currentTimeRegistered = null;
        #currentSeekableRange = null;
        #previousSeekableRange = null;
        get #timeshiftCanSeekForward() {
            return timeshiftUtils.canSeekForward(this.streamInformation?.mediaItem.mediaType, this.currentPosition, this.currentState, this.#currentSeekableRange, this.#timeshiftInfo?.forward_seeking);
        }
        get #timeshiftCanSeekBackward() {
            return timeshiftUtils.canSeekBackward(this.streamInformation?.mediaItem.mediaType, this.currentPosition, this.currentState, this.#currentSeekableRange);
        }
        get #timeshiftCanPause() {
            return timeshiftUtils.canPause(this.#timeshiftInfo, this.currentState, this.streamInformation?.mediaItem.mediaType, this.currentPosition, this.#currentSeekableRange);
        }
        get timeshiftAvailability() {
            if (this.#registeredTimeshiftAvailability === TimeshiftStatus.UNAVAILABLE) {
                return super.timeshiftAvailability;
            }
            return this.#registeredTimeshiftAvailability;
        }
        #canRegisterTimeshift() {
            return timeshiftUtils.canRegisterTimeshift(this.isLive, this.#timeshiftInfo);
        }
        #hasRegisteredTimeshift() {
            return timeshiftUtils.hasRegisteredTimeshift(this.#timeshiftInfo);
        }
        #isTimeshift() {
            return timeshiftUtils.isTimeshift(this.streamInformation?.mediaItem.mediaType);
        }
        #isTimeshiftModuleResponsible() {
            if (this.#registeredTimeshiftAvailability !== TimeshiftStatus.AVAILABLE) {
                return false;
            }
            return (this.#canRegisterTimeshift() ||
                this.#hasRegisteredTimeshift() ||
                this.#isTimeshift());
        }
        #cleanTimeshiftInfo() {
            this.#registeredTimeshiftAvailability = TimeshiftStatus.UNAVAILABLE;
            this.#channelId = null;
            this.#currentTimeRegistered = null;
            this.#timeshiftInfo = null;
        }
        #updateTimeshiftInformation(watchResponse, adapterRequest) {
            if (adapterRequest.type !== MediaType.LIVE) {
                return;
            }
            this.#registeredTimeshiftAvailability = watchResponse.register_timeshift
                ?? TimeshiftStatus.UNAVAILABLE;
            this.#channelId = adapterRequest.parameters.cid;
        }
        hookMediaItem(media) {
            const streamInformation = this.streamInformation;
            if (!streamInformation) {
                throw new Error('Not enough information');
            }
            if (this.isLive) {
                this.#updateTimeshiftInformation(streamInformation.watchResponse, streamInformation.adapterRequest);
            }
            const isUnrelatedStreamType = !(this.isLive ||
                this.#isTimeshift());
            if (isUnrelatedStreamType ||
                this.#registeredTimeshiftAvailability === TimeshiftStatus.UNAVAILABLE) {
                this.#cleanTimeshiftInfo();
                return super.hookMediaItem(media);
            }
            return {
                ...media,
                timeshiftAvailability: this.#registeredTimeshiftAvailability,
            };
        }
        #handleEdgeCase() {
            if (timeshiftUtils.shouldAutostart(this.streamInformation?.mediaItem.mediaType, this.#currentSeekableRange, this.#previousSeekableRange, this.currentState, this.currentPosition)) {
                this.play();
            }
            this.#previousSeekableRange = this.#currentSeekableRange;
        }
        setPlayerState(newState) {
            if (!this.#isTimeshiftModuleResponsible()) {
                return super.setPlayerState(newState);
            }
            if (newState.seekableRange) {
                // this.seekableRange will not be updated at this point
                // because it is set with the last super.setPlayerState call,
                // but we need to operate with the freshest available value here.
                // Otherwise there is a gap with the more frequent updating
                // currentPosition.
                this.#currentSeekableRange = newState.seekableRange;
            }
            this.#handleEdgeCase();
            return super.setPlayerState({
                ...newState,
                canPause: this.#timeshiftCanPause,
                canSeekBackward: this.#timeshiftCanSeekBackward,
                canSeekForward: this.#timeshiftCanSeekForward,
                seekableRange: this.#isTimeshift() ? this.#currentSeekableRange : null,
            });
        }
        async #registerTimeshift() {
            if (!this.#channelId) {
                return Promise.reject(new Error('Invalid channel Id'));
            }
            this.#currentTimeRegistered = this.currentPosition;
            const response = await registerTimeshift({ cid: this.#channelId });
            this.#timeshiftInfo = response;
            return Promise.resolve();
        }
        async #playTimeshift() {
            try {
                const playOptions = this.streamInformation?.adapterRequest.playOptions;
                if (!this.#channelId || !playOptions || !this.#timeshiftInfo?.registered_at) {
                    return Promise.reject(new Error('Not enough information'));
                }
                const capability = this.capability;
                await this.stop();
                const streamType = getStreamingProtocol(playOptions, capability);
                const param = {
                    stream_type: streamType,
                    https_watch_urls: true,
                    cid: this.#channelId,
                };
                if (capability?.subtitlesCodec) {
                    param.vtt = SubtitlesCodec.VTT === capability.subtitlesCodec;
                    param.ttml = SubtitlesCodec.TTML === capability.subtitlesCodec;
                }
                this.triggerEvent(EventKey.WATCH_REQUESTED);
                const watchResponse = await getTimeshiftStream(param);
                if (!watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent(EventKey.WATCH_RECEIVED, {
                    data: watchResponse,
                });
                const adapterRequest = {
                    type: MediaType.REGISTERED_TIMESHIFT,
                    parameters: {
                        cid: this.#channelId,
                    },
                    capability,
                    watchResponse,
                    playOptions: {
                        ...playOptions,
                        startupPosition: this.#currentTimeRegistered ?? 0,
                    },
                };
                const adapterMedia = await this.adapter.load(adapterRequest);
                const media = await this.dispatchMedia(adapterRequest, adapterMedia);
                return media;
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        pause() {
            if (!this.#isTimeshiftModuleResponsible()) {
                return super.pause();
            }
            if (this.#canRegisterTimeshift()) {
                this.#registerTimeshift();
            }
            return super.pause();
        }
        play() {
            if (this.#hasRegisteredTimeshift() && !this.#isTimeshift()) {
                this.#playTimeshift();
            }
            else {
                super.play();
            }
        }
        seek(position) {
            if (!this.#isTimeshift()) {
                super.seek(position);
                return;
            }
            if (!this.#currentSeekableRange) {
                return;
            }
            super.seek(timeshiftUtils.getSafeSeekPosition(position, this.#currentSeekableRange));
        }
    };
};
