export const WithControl = (Base) => {
    return class PlayerWithControl extends Base {
        play() {
            this.adapter.play();
        }
        pause() {
            this.adapter.pause();
        }
        stop() {
            return Promise.resolve();
        }
        seek(position) {
            this.adapter.seek(position);
        }
        seekBackward(steps) {
            if (this._currentPosition == null) {
                return;
            }
            const offset = steps * this._playerOptions.stepBackwardDuration;
            if (this.seekPosition == null) {
                this.seekPosition = this._currentPosition - offset;
            }
            else {
                this.seekPosition -= offset;
            }
            this.seek(this.seekPosition);
        }
        seekForward(steps) {
            if (this._currentPosition == null) {
                return;
            }
            const offset = steps * this._playerOptions.stepForwardDuration;
            if (this.seekPosition == null) {
                this.seekPosition = this._currentPosition + offset;
            }
            else {
                this.seekPosition += offset;
            }
            this.seek(this.seekPosition);
        }
        setVolume(value) {
            this._volume = value;
            this.adapter.setVolume(value);
        }
        setRate(rate) {
            rate;
            this._logger?.info('not implemented: setRate');
        }
    };
};
