import React, { useEffect, useState, } from 'react';
import { PlayerManager } from '../player/player-manager';
import { getEpg, formatDate, } from './utils';
import { ButtonsList } from '../layout/buttons-list';
import { useSessionContext } from '../authentication/context';
import { getChannels } from '../live/utils';
import { useOsd } from '../player/osd-context';
export const Replay = (props) => {
    const session = useSessionContext().session;
    const [epg, setEpg] = useState(null);
    const { setOsd } = useOsd();
    const channelId = props.channelId;
    useEffect(() => {
        if (!session) {
            return;
        }
        getEpg(session?.lineup_hash, getChannels()).then((response) => {
            setEpg(response?.channels);
        });
    }, [session]);
    const playProgram = (programId) => {
        const playerManager = PlayerManager.getInstance();
        const player = playerManager.getPlayer();
        if (!player || !epg) {
            return;
        }
        const programIdNum = parseInt(programId, 10);
        const program = epg[channelId]?.find((p) => p.id === programIdNum);
        if (program) {
            setOsd({
                programStart: program.s,
                programEnd: program.e,
                programId: program.id,
                channelId,
            });
        }
        else {
            return;
        }
        player.playProgram(channelId, programIdNum, {
            preferredAudioLanguage: 'de',
            preferredSubtitlesLanguage: 'de',
            program: {
                start: program.s,
                duration: program.e - program.s,
            },
        }).then((data) => console.log('playProgram - replay', data));
    };
    if (!session || !epg) {
        return null;
    }
    const onAirProgram = epg[channelId]?.slice(-1)[0];
    const programEntries = epg[channelId]?.slice(-3).map((program) => {
        return {
            title: onAirProgram?.id === program.id
                ? `onAir: ${program?.t}`
                : program.t,
            id: String(program.id),
            metaInfo: [
                `id: ${program.id}`,
                `start: ${formatDate(program.s)}`,
                `end: ${formatDate(program.e)}`,
            ],
        };
    }) || [];
    return (React.createElement(ButtonsList, { onItemSelected: playProgram, items: programEntries, listLabel: "Replay" }));
};
