import { Player as BitmovinPlayer, PlayerEvent as BitmovinPlayerEvent, TimeMode, TimelineReferencePoint, HttpRequestType, } from 'bitmovin-player/modules/bitmovinplayer-core';
import Abr from 'bitmovin-player/modules/bitmovinplayer-abr';
import ContainerMP4 from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import EngineBitmovin from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import XML from 'bitmovin-player/modules/bitmovinplayer-xml';
import Dash from 'bitmovin-player/modules/bitmovinplayer-dash';
import DRM from 'bitmovin-player/modules/bitmovinplayer-drm';
import MseRenderer from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import Subtitles from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import SubtitlesTTML from 'bitmovin-player/modules/bitmovinplayer-subtitles-ttml';
import SubtitlesWebVTT from 'bitmovin-player/modules/bitmovinplayer-subtitles-vtt';
import Thumbnail from 'bitmovin-player/modules/bitmovinplayer-thumbnail';
import { isPlaybackError, PlaybackError, } from '../../errors';
import { ErrorCode } from '../../errors/enums/error-code';
import { calculateAbsoluteLiveEdge, calculateAbsoluteTimeshift, isBitmovinError, mapAudioTrack, mapBitmovinError, mapTextTrack, } from './utils';
import { PlaybackState } from '../../player/enums';
import { AdapterCallbacksDispatcher } from '../adapter-callbacks-dispatcher';
import { MediaType } from '../../stream/enums';
import { CustomSubtitleDisplay, } from './subtitles-display';
import { createSourceConfiguration, } from './config';
const EVENT_TIMEOUT = 50;
export const BITMOVIN_LICENSE_KEY = '9ffbb098-947b-4336-8703-8518cd933233';
export class BitmovinAdapter extends AdapterCallbacksDispatcher {
    bitmovinPlayer;
    view;
    eventMap;
    sessionEventMap;
    pendingActivationSubtitlesTrack = null;
    subtitleDisplay = null;
    type = null;
    constructor(options) {
        super(options.logging?.adapterLogger);
        this.initPlayerModules();
        this.eventMap = {
            [BitmovinPlayerEvent.Ready]: this.onBitmovinPlayerReady,
            [BitmovinPlayerEvent.DownloadFinished]: this.onBitmovinPlayerDownloadFinished,
            [BitmovinPlayerEvent.SourceLoaded]: this.onBitmovinSourceLoaded,
            [BitmovinPlayerEvent.SourceUnloaded]: this.onBitmovinSourceUnloaded,
            [BitmovinPlayerEvent.TimeChanged]: this.onBitmovinTimeChanged,
            [BitmovinPlayerEvent.Playing]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.Paused]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.StallStarted]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.StallEnded]: this.onBitmovinPlaybackStateChanged,
            [BitmovinPlayerEvent.Error]: this.onBitmovinPlayerError,
            [BitmovinPlayerEvent.AudioChanged]: this.onBitmovinSelectedAudioTrackChanged,
            [BitmovinPlayerEvent.SubtitleDisabled]: this.onBitmovinSelectedSubtitleTrackChanged,
            [BitmovinPlayerEvent.SubtitleEnabled]: this.onBitmovinSelectedSubtitleTrackChanged,
            [BitmovinPlayerEvent.CueEnter]: this.onCueEnter,
            [BitmovinPlayerEvent.CueExit]: this.onCueExit,
            [BitmovinPlayerEvent.Seek]: this.onSeek,
            [BitmovinPlayerEvent.Seeked]: this.onBitmovinSeeked,
            [BitmovinPlayerEvent.TimeShifted]: this.onBitmovinSeeked,
        };
        // we get more control over tracks changed events when we handle them manually
        // when a new session starts
        this.sessionEventMap = {
            [BitmovinPlayerEvent.AudioAdded]: this.onBitmovinAvailableAudioTracksChanged,
            [BitmovinPlayerEvent.AudioRemoved]: this.onBitmovinAvailableAudioTracksChanged,
            [BitmovinPlayerEvent.SubtitleAdded]: this.onBitmovinAvailableSubtitlesTracksChanged,
            [BitmovinPlayerEvent.SubtitleRemoved]: this.onBitmovinAvailableSubtitlesTracksChanged,
        };
    }
    resetState() {
        super.resetState();
        this.pendingActivationSubtitlesTrack = null;
    }
    start(type, startupPosition, prePadding) {
        if (type === MediaType.RECORDING ||
            type === MediaType.REPLAY ||
            type === MediaType.VOD) {
            if (startupPosition === undefined) {
                return {
                    // Force both dynamic and static streams to start at the beginning
                    // with outside boundary value -1
                    startOffset: -1,
                    startOffsetTimelineReference: TimelineReferencePoint.Start,
                };
            }
            return {
                startOffset: (startupPosition ?? 0) + (prePadding ?? 0),
                startOffsetTimelineReference: TimelineReferencePoint.Start,
            };
        }
        if (startupPosition === undefined) {
            return undefined;
        }
        return {
            startOffset: startupPosition,
        };
    }
    async load(request) {
        this.resetState();
        this.type = request.type;
        const sourceConfig = createSourceConfiguration(request.capability, request.watchResponse.stream, this.start(request.type, request.playOptions.startupPosition, request.watchResponse.stream.padding?.pre));
        await this.bitmovinPlayer?.load(sourceConfig);
        this.dispatchAvailableAudioTracksChanged(this.getBitmovinAudioTracks());
        this.dispatchAvailableSubtitleTracksChanged(this.getBitmovinSubtitlesTracks());
        await this.setPreferredTracks(request.playOptions.preferredAudioLanguage, request.playOptions.preferredSubtitlesLanguage);
        await this.play();
        return Promise.resolve({
            url: request.watchResponse.stream.url,
            licenseUrl: request.watchResponse.stream.license_url ?? null,
        });
    }
    seek(position) {
        if (this.bitmovinPlayer?.isLive()) {
            const seekableRange = this.seekableRange();
            if (!seekableRange) {
                return;
            }
            this.bitmovinPlayer.timeShift(position - seekableRange.end);
        }
        else {
            this.bitmovinPlayer?.seek(position);
        }
    }
    setView(view) {
        this.view = view;
        const bitmovinPlayer = new BitmovinPlayer(view, {
            key: BITMOVIN_LICENSE_KEY,
        });
        const videoNode = document.createElement('video');
        videoNode.setAttribute('id', 'player');
        videoNode.setAttribute('playsinline', '');
        videoNode.setAttribute('webkit-playsinline', '');
        videoNode.style.width = '100%';
        videoNode.style.height = '100%';
        this.view.appendChild(videoNode);
        bitmovinPlayer.setVideoElement(videoNode);
        this.subtitleDisplay = CustomSubtitleDisplay(bitmovinPlayer?.getContainer());
        this.bitmovinPlayer = bitmovinPlayer;
        this.attachEvents();
    }
    setVolume(value) {
        this.bitmovinPlayer?.setVolume(value * 100);
    }
    /**
     * Override in KeplerAdapter
     *
     * @protected
     */
    initPlayerModules() {
        BitmovinPlayer.addModule(EngineBitmovin);
        BitmovinPlayer.addModule(XML);
        BitmovinPlayer.addModule(Dash);
        BitmovinPlayer.addModule(DRM);
        BitmovinPlayer.addModule(Abr);
        BitmovinPlayer.addModule(MseRenderer);
        BitmovinPlayer.addModule(ContainerMP4);
        BitmovinPlayer.addModule(Subtitles);
        BitmovinPlayer.addModule(SubtitlesTTML);
        BitmovinPlayer.addModule(SubtitlesWebVTT);
        BitmovinPlayer.addModule(Thumbnail);
    }
    onCueEnter = (event) => {
        this.subtitleDisplay?.showCue(event);
    };
    onCueExit = (event) => {
        this.subtitleDisplay?.hideCue(event);
    };
    onBitmovinSeeked = () => {
        if (this.type === MediaType.REPLAY || this.type === MediaType.RECORDING) {
            this.dispatchSeeked(this.bitmovinPlayer?.getCurrentTime(TimeMode.RelativeTime) ?? 0);
        }
        else {
            this.dispatchSeeked(this.bitmovinPlayer?.getCurrentTime() ?? 0);
        }
    };
    onSeek = () => {
        this.subtitleDisplay?.clear();
    };
    onBitmovinTimeChanged = (event) => {
        if (this.type === MediaType.REPLAY ||
            this.type === MediaType.RECORDING ||
            this.type === MediaType.VOD) {
            this.dispatchPositionChange(this.bitmovinPlayer?.getCurrentTime(TimeMode.RelativeTime) ?? 0);
            return;
        }
        this.dispatchPositionChange(event.time);
        // seekableRange and positionChange are used togehter
        // to determine if seeking is allowed.
        // We need to keep the values as close as possible to
        // the real current values to prevent state fluctuation.
        this.dispatchSeekableRange(this.seekableRange());
    };
    onBitmovinPlayerReady = () => {
        this.dispatchPlayerReady();
    };
    onBitmovinPlayerDownloadFinished = (event) => {
        if (this.bitmovinPlayer?.isPlaying()) {
            return;
        }
        if (event.downloadType === HttpRequestType.MANIFEST_DASH ||
            event.downloadType === HttpRequestType.MANIFEST_HLS_MASTER ||
            event.downloadType === HttpRequestType.MANIFEST_HLS_VARIANT) {
            this.dispatchSeekableRange(this.seekableRange());
        }
    };
    onBitmovinSourceLoaded = () => {
        this.attachSessionEvents();
        this.dispatchPlaybackDurationChanged(this.bitmovinPlayer?.getDuration() ?? null);
        this.dispatchPositionChange(this.bitmovinPlayer?.getCurrentTime() ?? 0);
        this.dispatchSeekableRange(this.seekableRange());
    };
    onBitmovinSourceUnloaded = (event) => {
        this.onBitmovinPlaybackStateChanged(event);
        this.detachSessionEvents();
    };
    getBitmovinAudioTracks() {
        return this.bitmovinPlayer?.getAvailableAudio().map((track) => mapAudioTrack(track)) || [];
    }
    getBitmovinSubtitlesTracks() {
        return this.bitmovinPlayer?.subtitles?.list().map((track) => mapTextTrack(track)) || [];
    }
    onBitmovinPlaybackStateChanged = (event) => {
        this.dispatchPlaybackStateChanged(this.mapState(event));
    };
    onBitmovinAvailableAudioTracksChanged = () => {
        this.dispatchAvailableAudioTracksChanged(this.getBitmovinAudioTracks());
    };
    onBitmovinAvailableSubtitlesTracksChanged = () => {
        this.dispatchAvailableSubtitleTracksChanged(this.getBitmovinSubtitlesTracks());
    };
    onBitmovinSelectedSubtitleTrackChanged = (event) => {
        this.subtitleDisplay?.clear();
        switch (event.type) {
            case BitmovinPlayerEvent.SubtitleDisabled: {
                if (this.pendingActivationSubtitlesTrack === null) {
                    this.dispatchSelectedSubtitlesTrackChanged(null);
                }
                break;
            }
            case BitmovinPlayerEvent.SubtitleEnabled: {
                const currentTrack = mapTextTrack(event.subtitle);
                this.pendingActivationSubtitlesTrack = null;
                this.dispatchSelectedSubtitlesTrackChanged(currentTrack);
                break;
            }
            default:
                break;
        }
    };
    onBitmovinSelectedAudioTrackChanged = (event) => {
        this.dispatchSelectedAudioTrackChanged(mapAudioTrack(event.targetAudio));
    };
    onBitmovinPlayerError = (error) => {
        this.handleError(error);
    };
    seekableRange() {
        if (!this.bitmovinPlayer) {
            return null;
        }
        if (this.bitmovinPlayer.isLive()) {
            if (this.type === MediaType.REPLAY || this.type === MediaType.RECORDING) {
                return {
                    start: 0,
                    end: Math.abs(this.bitmovinPlayer.getMaxTimeShift()),
                };
            }
            return {
                start: calculateAbsoluteTimeshift(this.bitmovinPlayer.getCurrentTime(), this.bitmovinPlayer.getTimeShift(), this.bitmovinPlayer.getMaxTimeShift()),
                end: calculateAbsoluteLiveEdge(this.bitmovinPlayer.getCurrentTime(), this.bitmovinPlayer.getTimeShift()),
            };
        }
        else {
            return {
                start: 0,
                end: this.bitmovinPlayer.getDuration(),
            };
        }
    }
    async setPreferredTracks(preferredAudioLanguage, preferredSubtitlesLanguage) {
        let preferredAudioTrack = this.findPreferredLanguageTrack(this.audioTracks, preferredAudioLanguage);
        const preferredSubtitlesTrack = this.findPreferredLanguageTrack(this.subtitlesTracks, preferredSubtitlesLanguage);
        if (preferredAudioTrack == null) {
            const currentAudio = this.bitmovinPlayer?.getAudio();
            if (currentAudio) {
                preferredAudioTrack = mapAudioTrack(currentAudio);
            }
        }
        await Promise.all([
            preferredAudioTrack
                ? this.setAudioTrack(preferredAudioTrack)
                : Promise.resolve(),
            preferredSubtitlesTrack
                ? this.setSubtitlesTrack(preferredSubtitlesTrack)
                : Promise.resolve(),
        ]);
        return Promise.resolve();
    }
    findPreferredLanguageTrack(availableTracks, preferredLanguage) {
        if (!preferredLanguage) {
            return null;
        }
        const preferredTrack = availableTracks.find((track) => {
            return track.locale === preferredLanguage;
        });
        if (!preferredTrack) {
            return null;
        }
        return preferredTrack;
    }
    attachEvents() {
        Object.entries(this.eventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.on(eventKey, listener);
        });
    }
    detachEvents() {
        Object.entries(this.eventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.off(eventKey, listener);
        });
    }
    attachSessionEvents() {
        Object.entries(this.sessionEventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.on(eventKey, listener);
        });
    }
    detachSessionEvents() {
        Object.entries(this.sessionEventMap).forEach(([eventKey, listener]) => {
            this.bitmovinPlayer?.off(eventKey, listener);
        });
    }
    play() {
        const player = this.bitmovinPlayer;
        if (!player) {
            return Promise.resolve();
        }
        if (!player.getSource()) {
            this.handleError(new PlaybackError(ErrorCode.Source, 'No source loaded'));
            return Promise.resolve();
        }
        return player.play()
            .catch((error) => {
            // catch browser autoplay blocked when video is unmuted
            if (error.name === 'NotAllowedError') {
                this.handleError(error);
                return;
            }
            // https://developer.chrome.com/blog/play-request-was-interrupted
            if (error.name === 'AbortError') {
                this.handleError(error);
                return;
            }
            this.handleError(error);
        });
    }
    pause() {
        const player = this.bitmovinPlayer;
        if (!player) {
            return Promise.resolve();
        }
        player.pause();
        return Promise.resolve();
    }
    timeoutEvent = (event, error) => {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(error);
            }, EVENT_TIMEOUT);
            const resolveCallback = () => {
                clearTimeout(timeout);
                this.bitmovinPlayer?.off(event, resolveCallback);
                resolve();
            };
            this.bitmovinPlayer?.on(event, resolveCallback);
        });
    };
    setAudioTrack(track) {
        const bitmovinSelectedAudioTrack = this.bitmovinPlayer?.getAudio();
        if (bitmovinSelectedAudioTrack?.id === track.id && this.selectedAudioTrack === null) {
            this.dispatchSelectedAudioTrackChanged(track);
            return;
        }
        this.bitmovinPlayer?.setAudio(track.id);
        this.timeoutEvent(BitmovinPlayerEvent.AudioChanged, new PlaybackError(ErrorCode.AudioTrack, 'Error changing audio track')).catch((error) => this.handleError(error));
    }
    setSubtitlesTrack(track) {
        this.pendingActivationSubtitlesTrack = track;
        if (this.selectedSubtitlesTrack) {
            this.bitmovinPlayer?.subtitles.disable(this.selectedSubtitlesTrack.id);
            this.timeoutEvent(BitmovinPlayerEvent.SubtitleDisabled, new PlaybackError(ErrorCode.SubtitlesTrack, 'Error disabling subtitles track')).catch((error) => this.handleError(error));
        }
        if (!track) {
            return;
        }
        this.bitmovinPlayer?.subtitles.enable(track.id, true);
        this.timeoutEvent(BitmovinPlayerEvent.SubtitleEnabled, new PlaybackError(ErrorCode.SubtitlesTrack, 'Error changing subtitles track')).catch((error) => this.handleError(error));
    }
    async stop() {
        const hasSource = Boolean(this.bitmovinPlayer?.getSource());
        if (hasSource) {
            this.bitmovinPlayer?.unload()
                .catch((error) => {
                this.handleError(error);
            });
            await this.timeoutEvent(BitmovinPlayerEvent.SourceUnloaded, new PlaybackError(ErrorCode.Unload, 'Error unloading source')).catch((error) => this.handleError(error));
        }
        this.detachEvents();
        this.detachSessionEvents();
        return Promise.resolve();
    }
    async destroy() {
        await this.stop();
        this.bitmovinPlayer?.destroy()
            .catch((error) => {
            this.handleError(error);
        });
        this.view = undefined;
        this.bitmovinPlayer = undefined;
        return Promise.resolve();
    }
    handleError(error) {
        const mappedError = this.mapError(error);
        this.dispatchError(mappedError);
    }
    mapError(error) {
        if (isPlaybackError(error)) {
            return error;
        }
        if (isBitmovinError(error)) {
            return mapBitmovinError(error);
        }
        return new PlaybackError(ErrorCode.Unknown, String(error));
    }
    mapState(event) {
        const stateMap = {
            [BitmovinPlayerEvent.Playing]: PlaybackState.PLAYING,
            [BitmovinPlayerEvent.Paused]: PlaybackState.PAUSED,
            [BitmovinPlayerEvent.StallStarted]: PlaybackState.BUFFERING,
            [BitmovinPlayerEvent.StallEnded]: PlaybackState.PLAYING,
            [BitmovinPlayerEvent.SourceUnloaded]: PlaybackState.STOPPED,
        };
        return stateMap[event.type];
    }
}
export { BitmovinPlayer, };
export { ModuleName, NetworkRequestApi, } from 'bitmovin-player';
