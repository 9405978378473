import React from 'react';
import { PlayerManager } from '../player/player-manager';
import { ButtonsList } from '../layout/buttons-list';
import { getChannels, needsDrm, } from '../live/utils';
import { useChannels } from '../live/channels-context';
export const Live = (props) => {
    const channels = useChannels();
    const sandboxChannels = channels?.filter((channel) => getChannels().includes(channel.cid));
    const playLive = (cid) => {
        const playerManager = PlayerManager.getInstance();
        const player = playerManager.getPlayer();
        const channel = channels?.find((c) => c.cid === cid);
        const drmRequired = channel ? needsDrm(channel) : false;
        player?.playLive(cid, {
            drmRequired,
        });
        props.onChannelSelected(cid);
    };
    if (!channels || !sandboxChannels) {
        return null;
    }
    return (React.createElement(ButtonsList, { onItemSelected: playLive, items: sandboxChannels.map((channel) => ({
            title: channel.title,
            id: channel.cid,
            metaInfo: [`drm: ${needsDrm(channel) ? 'yes' : 'no'}`],
        })), listLabel: "Live" }));
};
