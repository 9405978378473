import { EventKey } from '../enums';
export const WithUtil = (Base) => {
    return class PlayerWithUtil extends Base {
        triggerEvent = (eventKey, ...data) => {
            const event = new CustomEvent(eventKey, {
                detail: data[0],
            });
            const ignoredEventKeysForLogging = [
                EventKey.CURRENT_POSITION_CHANGED,
                EventKey.PLAYER_STATE_CHANGED,
            ];
            if (this._logger && !ignoredEventKeysForLogging.includes(eventKey)) {
                this._logger?.info('Dispatching event', eventKey, data);
            }
            this._eventTarget?.dispatchEvent(event);
        };
        triggerPlayerStateChanged() {
            this.triggerEvent(EventKey.PLAYER_STATE_CHANGED, {
                canSeekForward: this.canSeekForward,
                canSeekBackward: this.canSeekBackward,
                canPause: this.canPause,
                seekableRange: this.seekableRange,
            });
        }
        setPlayerState(newState) {
            const stateChanged = this._canSeekForward !== newState.canSeekForward ||
                this._canSeekBackward !== newState.canSeekBackward ||
                this._canPause !== newState.canPause ||
                this._seekableRange !== newState.seekableRange;
            if (!stateChanged) {
                return;
            }
            this._canSeekForward = newState.canSeekForward ?? this._canSeekForward;
            this._canSeekBackward = newState.canSeekBackward ?? this._canSeekBackward;
            this._canPause = newState.canPause ?? this._canPause;
            this._seekableRange = newState.seekableRange === undefined
                ? this._seekableRange
                : newState.seekableRange;
            this.triggerPlayerStateChanged();
        }
    };
};
