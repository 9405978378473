import { StreamProtocol, StreamProtection, } from '../../capability';
export const createSourceConfiguration = (capabilities, info, start) => {
    const config = {};
    if (start) {
        config.options = {
            startOffset: start.startOffset,
            startOffsetTimelineReference: start.startOffsetTimelineReference,
        };
    }
    switch (capabilities.streamProtocol) {
        case StreamProtocol.DASH:
            config.dash = info.url;
            if (!info.license_url) {
                return config;
            }
            switch (capabilities.streamProtection) {
                case StreamProtection.WIDEVINE:
                    return {
                        ...config,
                        drm: {
                            widevine: {
                                LA_URL: info.license_url,
                            },
                        },
                    };
                case StreamProtection.PLAYREADY:
                    return {
                        ...config,
                        drm: {
                            playready: {
                                LA_URL: info.license_url,
                            },
                        },
                    };
                default:
                    throw new Error('Unsupported DRM type');
            }
        case StreamProtocol.HLS:
            config.hls = info.url;
            if (!info.license_url) {
                return config;
            }
            switch (capabilities.streamProtection) {
                case StreamProtection.FAIRPLAY:
                    return {
                        ...config,
                        drm: {
                            fairplay: {
                                LA_URL: info.license_url,
                                certificateURL: '',
                            },
                        },
                    };
                default:
                    throw new Error('Unsupported DRM type');
            }
        default:
            throw new Error('Unsupported stream type');
    }
};
