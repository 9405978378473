import { EventTargetPolyfill } from './polyfills/event-target';
import { init as platformInit } from './platforms';
const initPolyfills = () => {
    EventTargetPolyfill.install();
};
export const init = async () => {
    initPolyfills();
    await platformInit();
    return Promise.resolve();
};
export { PlayerView, createPlayer, } from './platforms';
export { ChannelLogger, Client as WebClient, ConsoleLogger, EventKey, PlaybackState, } from '@zattoo/playback-sdk';
