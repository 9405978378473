import { MediaType } from '../../stream/enums';
export const WithProperty = (Base) => {
    return class PlayerWithProperty extends Base {
        get canSeekForward() {
            return this._canSeekForward;
        }
        get canSeekBackward() {
            return this._canSeekBackward;
        }
        get canPause() {
            return this._canPause;
        }
        get duration() {
            return this._duration;
        }
        get seekableRange() {
            return this._seekableRange;
        }
        get volume() {
            return this._volume;
        }
        get prePadding() {
            this._logger?.info('not implemented: prePadding');
            return 0;
        }
        get postPadding() {
            this._logger?.info('not implemented: prePadding');
            return 0;
        }
        get currentState() {
            return this._playbackState;
        }
        get currentPosition() {
            return this._currentPosition;
        }
        get isLive() {
            return this.mediaType === MediaType.LIVE;
        }
        get isStatsForNerdsEnabled() {
            this._logger?.info('not implemented: prePadding');
            return false;
        }
    };
};
