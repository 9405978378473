import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { ActivityIndicator, View, } from 'react-native';
import { init as initPlaybackSdk } from '@zattoo/playback-sdk-react-native';
import { styles } from './styles';
import { Harness } from './harness';
let tafConfiguration = {
    deviceUrl: 'ws://localhost:8002/ws/device',
};
export const tafSetUp = (configuration) => {
    tafConfiguration = configuration;
};
export const Taf = () => {
    const [playbackReady, setPlaybackReady] = useState(false);
    const [tafStatus, setTafStatus] = useState(false);
    const connection = useRef(null);
    const connect = useCallback(() => {
        console.log(`Connecting to ${tafConfiguration.deviceUrl}`);
        const endpoint = tafConfiguration.sessionId
            ? `${tafConfiguration.deviceUrl}/${tafConfiguration.sessionId}`
            : tafConfiguration.deviceUrl;
        const socket = new WebSocket(endpoint);
        socket.addEventListener('open', () => {
            connection.current = socket;
            setTafStatus(true);
        });
        socket.addEventListener('close', () => {
            connection.current = null;
            setTafStatus(false);
            setTimeout(connect, 5000);
        });
        socket.addEventListener('error', (e) => {
            console.log(e);
        });
    }, []);
    const disconnect = () => {
        connection.current?.close();
    };
    useEffect(() => {
        Promise.all([
            initPlaybackSdk(),
        ]).then(() => {
            console.log('PlaybackSDK is ready');
            setPlaybackReady(true);
        });
    }, []);
    useEffect(() => {
        console.log('Root use effect');
        connect();
        return () => {
            disconnect();
            connection.current = null;
        };
    }, [connect]);
    if (!connection.current || !tafStatus || !playbackReady) {
        return (React.createElement(View, { style: styles.loadingIndicator },
            React.createElement(ActivityIndicator, { size: "large" })));
    }
    else {
        return React.createElement(Harness, { websocket: connection.current });
    }
};
