import * as Zapi from '@zattoo/zapi';
export const establishSession = async (login, password) => {
    await Zapi.helloSessionV3({
        app_tid: 'f3a1b87a-1c11-45e7-839d-e478cb4278da',
        app_version: '9.9.9',
        uuid: 'ca1e30f8-c257-4471-a8dd-ac3cd6ad04d8',
    });
    const session = await Zapi.getSessionV3();
    if (session?.active && session?.account) {
        return session;
    }
    return Zapi.loginAccountV3({
        login,
        password,
    });
};
export const logout = async () => {
    await Zapi.logoutAccountV3();
};
