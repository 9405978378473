import * as Zapi from '@zattoo/zapi';
const today = Math.round(Date.now() / 1000);
const day = 24 * 60 * 60;
export const getEpg = (lineupHash, cids) => {
    return Zapi.getEPG({
        luhash: lineupHash,
        start: today - day,
        end: today,
        cids,
    });
};
export const formatDate = (date) => {
    const value = new Date(date * 1000);
    const hours = String(value.getHours()).padStart(2, '0');
    const minutes = String(value.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};
