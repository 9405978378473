import { TimeshiftStatus } from '@zattoo/zapi/lib/stream/enums';
import { SEEK_BACK_MARGIN, SEEK_FORWARD_MARGIN, } from '../utils';
import { PlaybackState } from '../../../player/enums';
export const isModuleResponsible = (isLive, timeshiftAvailability) => {
    return (isLive &&
        timeshiftAvailability === TimeshiftStatus.AVAILABLE);
};
export const canSeekForward = (currentPosition, currentState, seekableRange) => {
    if (!currentPosition ||
        !seekableRange?.end ||
        currentState === PlaybackState.STOPPED) {
        return false;
    }
    return seekableRange.end > currentPosition + SEEK_FORWARD_MARGIN;
};
export const canSeekBackward = (currentPosition, currentState, seekableRange) => {
    if (!currentPosition ||
        seekableRange?.start === undefined ||
        currentState === PlaybackState.STOPPED) {
        return false;
    }
    return currentPosition > seekableRange.start + SEEK_BACK_MARGIN;
};
export const canPause = (currentPosition, currentState, seekableRange) => {
    return (currentState === PlaybackState.PLAYING &&
        canSeekBackward(currentPosition, currentState, seekableRange));
};
export { getSafeSeekPosition } from '../utils';
