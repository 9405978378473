import { getRecordingStream } from '@zattoo/zapi';
import { MediaType } from './enums';
import { SubtitlesCodec, getStreamingProtocol, } from '../capability';
import { EventKey } from '../player/enums';
export const PlayerWithRecording = (Base) => {
    return class extends Base {
        watchResponse = null;
        get isRecording() {
            return this.mediaType === MediaType.RECORDING;
        }
        async playRecording(id, playOptions) {
            try {
                const capability = this.capability;
                await this.stop();
                const streamType = getStreamingProtocol(playOptions, capability);
                const param = {
                    stream_type: streamType,
                    recording_id: id.toString(10),
                    https_watch_urls: true,
                };
                if (capability?.subtitlesCodec) {
                    param.vtt = SubtitlesCodec.VTT === capability.subtitlesCodec;
                    param.ttml = SubtitlesCodec.TTML === capability.subtitlesCodec;
                }
                this.triggerEvent(EventKey.WATCH_REQUESTED);
                this.watchResponse = await getRecordingStream(param);
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent(EventKey.WATCH_RECEIVED, {
                    data: this.watchResponse,
                });
                const adapterRequest = {
                    type: MediaType.RECORDING,
                    parameters: {
                        recordingId: id,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                };
                const adapterMedia = await this.adapter.load(adapterRequest);
                return this.dispatchMedia(adapterRequest, adapterMedia);
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isRecording) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: true,
                canSeekBackward: true,
                canSeekForward: this.watchResponse?.stream?.forward_seeking,
                seekableRange: null,
                ...newState,
            });
        }
    };
};
