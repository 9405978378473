class CustomEvent {
    type;
    detail;
    constructor(type, customEvent) {
        this.type = type;
        this.detail = customEvent?.detail;
    }
}
class EventTarget {
    events;
    constructor() {
        this.events = new Map();
    }
    dispatchEvent(event) {
        const listeners = this.events.get(event.type);
        if (listeners) {
            listeners.forEach((listener) => listener(event));
        }
    }
    addEventListener(type, listener) {
        if (!this.events.has(type)) {
            this.events.set(type, new Map());
        }
        this.events.get(type)?.set(listener, listener);
    }
    removeEventListener(type, listener) {
        const listeners = this.events.get(type);
        if (listeners) {
            listeners.delete(listener);
        }
    }
}
export const EventTargetPolyfill = {
    install() {
        if (!global.EventTarget) {
            // @ts-expect-error type incompatible
            global.EventTarget = EventTarget;
        }
        if (!global.CustomEvent) {
            // @ts-expect-error type incompatible
            global.CustomEvent = CustomEvent;
        }
    },
};
