import { PlayerError as BitmovinPlayerError, } from 'bitmovin-player/modules/bitmovinplayer-core';
import { PlaybackError } from '../../errors';
import { ErrorCode } from '../../errors/enums/error-code';
export const mapAudioTrack = (track) => {
    const qualities = track.getQualities();
    return {
        id: track.id,
        locale: track.lang,
        codec: qualities[0]?.codec,
    };
};
export const mapTextTrack = (track) => {
    return {
        id: track.id,
        locale: track.lang,
    };
};
export const isBitmovinError = (error) => {
    return error instanceof BitmovinPlayerError || (
    // @ts-expect-error undefined type
    error?.type === 'error' &&
        // @ts-expect-error undefined type
        error?.name);
};
export const mapBitmovinError = (error) => {
    let message = `${error.message} | BitmovinName: ${error.name} | BitmovinCode: ${error.code}`;
    if (error.data) {
        message += ` | Data: ${JSON.stringify(error.data)}`;
    }
    if (error.sourceIdentifier) {
        message += ` | ${error.sourceIdentifier}`;
    }
    const playbackError = new PlaybackError(ErrorCode.Unknown, message);
    playbackError.stack = error.stack;
    return playbackError;
};
export const calculateAbsoluteLiveEdge = (currentTime, offsetToLiveEdge) => {
    return currentTime - offsetToLiveEdge;
};
export const calculateAbsoluteTimeshift = (currentTime, offsetToLiveEdge, // negative number
maxTimeshift) => {
    return calculateAbsoluteLiveEdge(currentTime, offsetToLiveEdge) + maxTimeshift;
};
