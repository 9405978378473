import React, { Component } from 'react';
import { PlayerManager } from '../player/player-manager';
import { SessionContext } from './context';
import { KeyInput } from './key-input';
import * as crypto from './crypto';
import { encryptedCredentials } from './credentials';
import { ButtonsList } from '../layout/buttons-list';
import { establishSession, logout, } from './utils';
export class SessionLoader extends Component {
    state = {
        session: null,
        error: null,
        key: '',
        availableCredentials: null,
        user: null,
    };
    loadCredentials() {
        let credentials = null;
        try {
            const decrypted = crypto.decrypt(encryptedCredentials, this.state.key);
            credentials = JSON.parse(decrypted);
        }
        catch (error) {
            console.log('decryption error', error);
        }
        if (!credentials) {
            return;
        }
        this.setState({
            availableCredentials: credentials,
        }, () => {
            this.autoAuthenticate();
        });
    }
    autoAuthenticate() {
        if (!this.state.user) {
            return;
        }
        const credentials = this.state.availableCredentials?.find((c) => c.login === this.state.user);
        if (!credentials) {
            return;
        }
        this.authenticate(credentials.login, credentials.password);
    }
    authenticate(login, password) {
        establishSession(login, password).then((session) => {
            if (!session.account) {
                throw new Error('Invalid Session');
            }
            PlayerManager.setPublicId(session.account.public_id);
            this.setState({ session });
        }).catch((error) => {
            this.setState({ error });
        });
    }
    handleKeyChange = (key) => {
        this.setState({ key });
    };
    handleKeySubmit = () => {
        this.loadCredentials();
    };
    logout() {
        this.setState({
            session: null,
            key: '',
            availableCredentials: null,
            user: null,
        });
        logout();
    }
    render() {
        if (this.state.error) {
            throw this.state.error;
        }
        if (!this.state.session?.active) {
            return this.state.availableCredentials
                ? (React.createElement(ButtonsList, { listLabel: "Select a user", items: this.state.availableCredentials.map((credentials) => ({
                        title: credentials.login,
                        id: credentials.login,
                    })), onItemSelected: (id) => {
                        const credentials = this.state.availableCredentials?.find((c) => c.login === id);
                        if (credentials) {
                            this.authenticate(credentials.login, credentials.password);
                        }
                    } })) : (React.createElement(KeyInput, { value: this.state.key, onChange: this.handleKeyChange, onSubmitEditing: this.handleKeySubmit }));
        }
        return (React.createElement(SessionContext.Provider, { value: {
                logout: this.logout.bind(this),
                session: this.state.session,
            } }, this.props.children));
    }
}
