import React, { useState, useEffect, } from 'react';
import { ChannelsContext } from './channels-context';
import { getChannelsInfo } from './utils';
import { useSessionContext } from '../authentication/context';
export const ChannelsLoader = (props) => {
    const session = useSessionContext().session;
    const [channels, setChannels] = useState(null);
    useEffect(() => {
        if (!session) {
            throw new Error('Invalid Session');
        }
        getChannelsInfo(session.power_guide_hash).then((channelsResponse) => {
            setChannels(channelsResponse?.channels || null);
        });
    }, [session]);
    if (!channels) {
        return null;
    }
    return (React.createElement(ChannelsContext.Provider, { value: channels }, props.children));
};
