import { isZAPIError, } from '@zattoo/zapi/lib/helpers/error';
import { PlayerWithTimeshift } from '../stream/timeshift';
import { PlayerWithLive } from '../stream/live';
import { PlayerWithReplay } from '../stream/replay';
import { EventKey, PlaybackState, } from './enums';
import { isPlaybackError, PlaybackError, } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { createMediaItem } from './utils';
import { WithProperty } from './modules/property';
import { WithControl } from './modules/control';
import { WithTrack } from './modules/track';
import { WithEvent } from './modules/event';
import { WithDebug } from './modules/debug';
import { PlayerWithRecording } from '../stream/recording';
import { WithUtil } from './modules/util';
export class BasePlayer {
    _canPause = false;
    _canSeekBackward = false;
    _canSeekForward = false;
    _capability;
    _currentPosition = null;
    _duration = null;
    _eventTarget = new EventTarget();
    _logger;
    _playbackState = PlaybackState.STOPPED;
    _seekableRange = null;
    _streamInformation = null;
    _volume = 1;
    _playerOptions;
    adapter;
    triggerEvent = () => {
        /* noop */
    };
    setPlayerState(newState) {
        newState;
        /* noop */
    }
    seekPosition = null;
    constructor(playerOptions, adapter, capability) {
        this.adapter = adapter;
        this._playerOptions = playerOptions;
        this._playerOptions.stepBackwardDuration ??= 30;
        this._playerOptions.stepForwardDuration ??= 60;
        this._capability = capability;
        this._logger = playerOptions.logging?.playerLogger;
    }
    hookMediaItem(media) {
        return media;
    }
    dispatchMedia(adapterRequest, adapterMedia, dynamic) {
        const media = createMediaItem(adapterRequest, adapterMedia, dynamic);
        this._streamInformation = {
            mediaItem: media,
            watchResponse: adapterRequest.watchResponse,
            adapterRequest,
        };
        // allows mixins to extend the media item.
        // happens after streamInformation is set,
        // so that mixins have all information available
        const extendedMedia = this.hookMediaItem(media);
        this._streamInformation.mediaItem = extendedMedia;
        this.triggerEvent(EventKey.MEDIA_CHANGED, {
            media: extendedMedia,
        });
        return Promise.resolve(extendedMedia);
    }
    destroy() {
        return this.adapter.destroy();
    }
    get capability() {
        return this._capability;
    }
    get streamInformation() {
        return this._streamInformation;
    }
    get mediaType() {
        if (!this._streamInformation) {
            return null;
        }
        return this._streamInformation.mediaItem.mediaType;
    }
    handleError(error) {
        const mappedError = this.mapError(error);
        this.triggerEvent(EventKey.PLAYER_ERROR, {
            error: mappedError,
        });
    }
    mapError(error) {
        if (isPlaybackError(error) || isZAPIError(error)) {
            return error;
        }
        return new PlaybackError(ErrorCode.Unknown, String(error));
    }
}
const PlayerWithModules = WithDebug(WithEvent(WithUtil(WithProperty(WithTrack(WithControl(BasePlayer))))));
export const Player = PlayerWithRecording(PlayerWithReplay(PlayerWithTimeshift(PlayerWithLive(PlayerWithModules))));
