import * as Zapi from '@zattoo/zapi';
export const getChannels = () => {
    return [
        'ard',
        'zdf',
        'DE_arte',
        'rtl',
        'cnn-international',
    ];
};
export const getChannelsInfo = (pghash) => {
    return Zapi.getChannelsV4({ pghash });
};
export const needsDrm = (channel) => {
    return Boolean(channel?.qualities.some((quality) => quality.drm_required));
};
