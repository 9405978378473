/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Player_MediaTrack } from "./player";
export const protobufPackage = "taf";
export var PlayerOutput_PlaybackStateChanged_State;
(function (PlayerOutput_PlaybackStateChanged_State) {
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["STOPPED"] = 0] = "STOPPED";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["BUFFERING"] = 1] = "BUFFERING";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["PAUSED"] = 2] = "PAUSED";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["PLAYING"] = 3] = "PLAYING";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(PlayerOutput_PlaybackStateChanged_State || (PlayerOutput_PlaybackStateChanged_State = {}));
export function playerOutput_PlaybackStateChanged_StateFromJSON(object) {
    switch (object) {
        case 0:
        case "STOPPED":
            return PlayerOutput_PlaybackStateChanged_State.STOPPED;
        case 1:
        case "BUFFERING":
            return PlayerOutput_PlaybackStateChanged_State.BUFFERING;
        case 2:
        case "PAUSED":
            return PlayerOutput_PlaybackStateChanged_State.PAUSED;
        case 3:
        case "PLAYING":
            return PlayerOutput_PlaybackStateChanged_State.PLAYING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return PlayerOutput_PlaybackStateChanged_State.UNRECOGNIZED;
    }
}
export function playerOutput_PlaybackStateChanged_StateToJSON(object) {
    switch (object) {
        case PlayerOutput_PlaybackStateChanged_State.STOPPED:
            return "STOPPED";
        case PlayerOutput_PlaybackStateChanged_State.BUFFERING:
            return "BUFFERING";
        case PlayerOutput_PlaybackStateChanged_State.PAUSED:
            return "PAUSED";
        case PlayerOutput_PlaybackStateChanged_State.PLAYING:
            return "PLAYING";
        case PlayerOutput_PlaybackStateChanged_State.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBasePlayerOutput() {
    return {};
}
export const PlayerOutput = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return PlayerOutput.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayerOutput();
        return message;
    },
};
function createBasePlayerOutput_PlaybackStateChanged() {
    return { state: 0 };
}
export const PlayerOutput_PlaybackStateChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== 0) {
            writer.uint32(8).int32(message.state);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_PlaybackStateChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.state = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { state: isSet(object.state) ? playerOutput_PlaybackStateChanged_StateFromJSON(object.state) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.state !== 0) {
            obj.state = playerOutput_PlaybackStateChanged_StateToJSON(message.state);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_PlaybackStateChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_PlaybackStateChanged();
        message.state = object.state ?? 0;
        return message;
    },
};
function createBasePlayerOutput_AvailableAudioTracksChanged() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableAudioTracksChanged = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableAudioTracksChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableAudioTracksChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableAudioTracksChanged();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_AvailableSubtitlesTrackChanged() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableSubtitlesTrackChanged = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableSubtitlesTrackChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableSubtitlesTrackChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableSubtitlesTrackChanged();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_SelectedAudioTrackChanged() {
    return { track: undefined };
}
export const PlayerOutput_SelectedAudioTrackChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedAudioTrackChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedAudioTrackChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedAudioTrackChanged();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_SelectedSubtitlesTrackChanged() {
    return { track: undefined };
}
export const PlayerOutput_SelectedSubtitlesTrackChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedSubtitlesTrackChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedSubtitlesTrackChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedSubtitlesTrackChanged();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_AvailableAudioTracks() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableAudioTracks = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableAudioTracks();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableAudioTracks.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableAudioTracks();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_SelectedAudioTrack() {
    return { track: undefined };
}
export const PlayerOutput_SelectedAudioTrack = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedAudioTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedAudioTrack.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedAudioTrack();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_AvailableSubtitlesTracks() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableSubtitlesTracks = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableSubtitlesTracks();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableSubtitlesTracks.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableSubtitlesTracks();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_SelectedSubtitlesTrack() {
    return { track: undefined };
}
export const PlayerOutput_SelectedSubtitlesTrack = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedSubtitlesTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedSubtitlesTrack.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedSubtitlesTrack();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
