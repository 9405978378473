export const createMediaItem = (adapterRequest, adapterMedia, dynamic) => {
    return {
        url: adapterMedia.url,
        licenseUrl: adapterMedia.licenseUrl,
        prePadding: adapterRequest.watchResponse.stream.padding?.pre ?? 0,
        postPadding: adapterRequest.watchResponse.stream.padding?.post ?? 0,
        mediaType: adapterRequest.type,
        dynamic: dynamic ?? false,
    };
};
