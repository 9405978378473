import { getLiveStream } from '@zattoo/zapi';
import { MediaType } from './enums';
import { SubtitlesCodec, getStreamingProtocol, } from '../capability';
import { EventKey } from '../player/enums';
export const MAX_TIMESHIFT_OFFSET = 10800;
export const PlayerWithLive = (Base) => {
    return class extends Base {
        watchResponse = null;
        async playLive(channelId, playOptions) {
            try {
                const capability = this.capability;
                await this.stop();
                const streamType = getStreamingProtocol(playOptions, capability);
                const param = {
                    stream_type: streamType,
                    https_watch_urls: true,
                    cid: channelId,
                    timeshift: MAX_TIMESHIFT_OFFSET,
                };
                if (capability?.subtitlesCodec) {
                    param.vtt = SubtitlesCodec.VTT === capability.subtitlesCodec;
                    param.ttml = SubtitlesCodec.TTML === capability.subtitlesCodec;
                }
                this.triggerEvent(EventKey.WATCH_REQUESTED);
                this.watchResponse = await getLiveStream(param);
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent(EventKey.WATCH_RECEIVED, {
                    data: this.watchResponse,
                });
                const adapterRequest = {
                    type: MediaType.LIVE,
                    parameters: {
                        cid: channelId,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                };
                const adapterMedia = await this.adapter.load(adapterRequest);
                const media = await this.dispatchMedia(adapterRequest, adapterMedia);
                return media;
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isLive) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: false,
                canSeekBackward: false,
                canSeekForward: false,
                seekableRange: null,
                ...newState,
            });
        }
    };
};
